import { userEmailKey, userIdKey, promoCodeKey, deliveryInfoCookieKey } from './../constants';
import { smsNotificationReqKey, userPhoneCookieKey } from "../constants";

export const setCookieHours = (name: string, value: any, hours?: number) => {
  let expires = "";
  const templateDate = new Date();

  if (hours) {
    templateDate.setTime(templateDate.getTime() + hours * 60 * 60 * 1000);
  } else {
    const afterTenYears = 10 * 365 * 24 * 60 * 60 * 1000;
    templateDate.setTime(templateDate.getTime() + (afterTenYears));
  }
  expires = "; expires=" + templateDate.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export const createDeliveryAddressKey = () => {
  return deliveryInfoCookieKey
}

export const setCookie = (name: string, value: any, days?: number) => {
  setCookieHours(name, value, days && days * 24);
};

export const getCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ")
      c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0)
      return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name: string) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const clearAllCookies = (bizName: string) => {
  eraseCookie(userIdKey);
  eraseCookie(userEmailKey);
  eraseCookie(promoCodeKey);
  eraseCookie(userPhoneCookieKey);
  eraseCookie(smsNotificationReqKey);
  eraseCookie(`${bizName}-announcement`);
}