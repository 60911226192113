import classes from "../Checkout.module.scss";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { useCallback, useMemo } from "react";
import { CheckoutFormStatus, DeliveryInfo, DestinationStatus } from "../../../Types/Types";
import { addressFieldRequiredMessage, defaultExcludedCities, deliveryDistanceFetchErrorMessage } from "../../../constants";
import { Typography } from "@material-ui/core";
import { createDeliveryAddressKey, setCookie } from "../../../common/cookies";


const cityLevelTypes = [
    "locality",
    "political",
];

export const useDeliveryAddress = () => {
    const finalOrder = useTypedSelector((state) => state.orderDetails.order);
    const { businessInfo } = useTypedSelector((state) => state.businessInfo);
    const { checkoutFormStatus } = useTypedSelector((state) => state.appLocalStatus);
    const { setDeliveryInfo, updateDestStatus } = useActions();

    const deliveryUnavailableMessage = businessInfo?.deliveryUnavailableForAddressMessage;
    const destinationInfo = finalOrder.deliveryAddress;
    const excludedCities = businessInfo?.excludedCities ?? defaultExcludedCities;

    const isCityExcluded = useCallback((deliveryAddr?: DeliveryInfo) => {
        const addrComponents = deliveryAddr?.addressComponents;
        if (!addrComponents || !excludedCities) {
            return false;
        }

        const cityComponent = addrComponents.find(x => x.types.find(type => cityLevelTypes.includes(type)))
        if (!cityComponent) {
            return false;
        }

        const excludedCitiesList = excludedCities.split(",").map(x => x.trim());
        const matchingCity = excludedCitiesList.find(x => cityComponent.long_name === x || cityComponent.short_name === x);
        return !matchingCity ? false : true;
    }, [excludedCities]);

    const fetchDistance = useCallback(async (origin: google.maps.LatLng, destination: google.maps.LatLng) => {

        const request: google.maps.DistanceMatrixRequest = {
            origins: [origin] as Array<google.maps.LatLng>,
            destinations: [destination] as Array<google.maps.LatLng>,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
        };

        try {
            const service = new google.maps.DistanceMatrixService();
            const resp = await service.getDistanceMatrix(request);

            const element = resp?.rows[0]?.elements
                ?.filter(x => x.status === google.maps.DistanceMatrixElementStatus.OK);

            if (!element || element.length === 0) {
                updateDestStatus(DestinationStatus.error);
                return false;
            }

            return element;
        } catch (error) {
            console.error(error);
            updateDestStatus(DestinationStatus.error);
        }
    }, []);


    const ValidateDeliveryAddress = useCallback((deliveryAddr?: DeliveryInfo) => {
        if (finalOrder.orderType !== "delivery") return true;
        if (!deliveryAddr) return false;
        if (!deliveryAddr?.distance) return false;
        if (!deliveryAddr?.duration) return false;
        if (!businessInfo) return true;
        if (!businessInfo.deliveryDistanceInMeters) return false;

        if (isCityExcluded(deliveryAddr)) {
            deliveryAddr.destinationStatus = DestinationStatus.error;
            setDeliveryInfo(deliveryAddr);
            return false;
        }

        const isDistanceInLimit = deliveryAddr?.distance <= businessInfo.deliveryDistanceInMeters;
        const isDurationInLimit = deliveryAddr?.duration <= businessInfo.deliveryDurationInSeconds;
        if (!isDistanceInLimit || !isDurationInLimit) {
            deliveryAddr.destinationStatus = DestinationStatus.unavailable;
            setDeliveryInfo(deliveryAddr);
            return false;
        }

        const destinationStatus = deliveryAddr?.destinationStatus;
        if (destinationStatus === DestinationStatus.valid) {
            setDeliveryInfo(deliveryAddr);
            return true;
        }

        const isValid = !!deliveryAddr.completeAddress;
        if (isValid) {
            deliveryAddr.destinationStatus = DestinationStatus.valid;
            setDeliveryInfo(deliveryAddr);
            return true;
        }

        return isValid;
    }, [finalOrder.orderType, businessInfo]);


    const ValidateDestination = useCallback(async (destination: google.maps.LatLng, deliveryInfo: DeliveryInfo) => {
        if (!businessInfo?.addressLatitude || !businessInfo?.addressLongitude) {
            return deliveryInfo;
        }

        const origin = new google.maps.LatLng(
            Number(businessInfo.addressLatitude),
            Number(businessInfo.addressLongitude)
        );

        const destinationResp = await fetchDistance(origin, destination);
        if (!destinationResp || destinationResp.length === 0) return deliveryInfo;

        const result = destinationResp[0];
        deliveryInfo.distance = result.distance.value;
        deliveryInfo.duration = result.duration.value;

        if (isCityExcluded(deliveryInfo)) {
            deliveryInfo.destinationStatus = DestinationStatus.unavailable;
            return deliveryInfo;
        }

        ValidateDeliveryAddress(deliveryInfo);
        return deliveryInfo;
    }, [fetchDistance, businessInfo?.addressLatitude, businessInfo?.addressLongitude, destinationInfo, isCityExcluded, ValidateDeliveryAddress]);

    const ErrorMessage = useMemo(() => {
        let message: string | undefined;
        let className: string | undefined;

        switch (checkoutFormStatus) {
            case CheckoutFormStatus.missingFields:
                if (!destinationInfo?.completeAddress) {
                    message = addressFieldRequiredMessage;
                    className = classes.errorText;
                }
                break;
            case CheckoutFormStatus.standby:
                message = undefined;
                className = undefined;
                break;
            default:
                break;
        }

        if (!destinationInfo?.completeAddress) {
            return <div className={className}>
                <Typography>{message}</Typography>
            </div>;
        }

        if (!message && isCityExcluded(destinationInfo)) {
            message = deliveryUnavailableMessage;
            className = classes.errorText;
        }

        if (!message) {
            switch (destinationInfo.destinationStatus) {
                case DestinationStatus.valid:
                case DestinationStatus.standby:
                    message = undefined;
                    className = undefined;
                    break;
                case DestinationStatus.error:
                    message = deliveryDistanceFetchErrorMessage;
                    className = classes.errorText;
                    break;
                case DestinationStatus.unavailable:
                    message = deliveryUnavailableMessage;
                    className = classes.errorText;
                    break;
                default:
                    break;
            }
        }
        if (!message) return <></>

        return <div className={className}>
            <Typography>{message}</Typography>
        </div>
    }, [destinationInfo, checkoutFormStatus, deliveryUnavailableMessage, isCityExcluded])

    return { ValidateDeliveryAddress, isCityExcluded, ErrorMessage, ValidateDestination };
};
