import classes from "./Checkout.module.scss";
import { deliveryInstructionsHelperText, deliveryInstructionsLabel, deliveryInstructionsPlaceholder, deliveryUnitFieldLabel, deliveryUnitPlaceholder, isDebug } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { GoogleAutocomplete } from "./GoogleAutocomplete";
import { DestinationStatus } from "../../Types/Types";
import { TextField } from "@material-ui/core";
import { useDeliveryAddress } from "./hooks/useDeliveryAddress";

interface DeliveryFieldsProps { }

const DeliveryFields = (props: DeliveryFieldsProps) => {
    const { } = props;
    const { setDeliveryInfo, updateDestStatus } = useActions();
    const { ErrorMessage, } = useDeliveryAddress();
    const finalOrder = useTypedSelector((state) => state.orderDetails.order);
    const destinationInfo = finalOrder.deliveryAddress;

    if (finalOrder.orderType !== "delivery") return <></>;

    return <>
        <section className={classes.deliveryFields}>

            {isDebug && <button onClick={e => updateDestStatus(DestinationStatus.error)}>
                Press
            </button>}

            <GoogleAutocomplete />

            <TextField
                type="text"
                name="unitNumber"
                autoComplete="off"
                label={deliveryUnitFieldLabel}
                className={classes.textField}
                placeholder={deliveryUnitPlaceholder}
                value={destinationInfo?.unitNumber}
                inputProps={{
                    maxLength: 50,
                    onInput: (e) => {
                        setDeliveryInfo({
                            ...destinationInfo,
                            destinationStatus: DestinationStatus.standby,
                            unitNumber: e.currentTarget.value.toString(),
                        })
                    }
                }}
            />
            <TextField
                type="text"
                autoComplete="off"
                name="deliveryInstructions"
                className={classes.textField}
                label={deliveryInstructionsLabel}
                placeholder={deliveryInstructionsPlaceholder}
                helperText={deliveryInstructionsHelperText}
                value={destinationInfo?.deliveryInstruction}
                inputProps={{
                    maxLength: 150,
                    onInput: (e) => {
                        setDeliveryInfo({
                            ...destinationInfo,
                            destinationStatus: DestinationStatus.standby,
                            deliveryInstruction: e.currentTarget.value,
                        })
                    }
                }}
            />

            {ErrorMessage}

        </section>
    </>
}


export default DeliveryFields;