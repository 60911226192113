import classes from "./Checkout.module.scss";
import { useParams } from "react-router";
import { OrderMode, StoreOrderingState, orderTypeMap, userPhoneCookieKey } from "../../constants";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IPublicProfileRouteParams } from "../../Types/RouteTypes";
import { useActions } from "../../hooks/useActions";
import { useCallback, useMemo } from "react";
import { isNumber } from "../../common/price/price";
import { setCookie } from "../../common/cookies";
import { validatePhone } from "../../common/phoneUtils";
import { getOrderMode } from "../../common/app";
import { CheckoutFormStatus } from "../../Types/Types";
import { useDeliveryAddress } from "./hooks/useDeliveryAddress";
import { PaymentSheetHelper } from "../../api/order";
import AppButton from "../AppButton/AppButton";
import usePriceAdjustments from "../ConfirmOrder/hooks/usePriceDetails";

interface CheckoutFooterProps {
    handleNext: Function;
}


const CheckoutFooter = (props: CheckoutFooterProps) => {
    const { handleNext } = props;
    const { setCompleteFinalOrder, updateCheckoutFormStatus } = useActions();
    const { setAppLoaded, setAppLoading } = useActions();
    const { ValidateDeliveryAddress } = useDeliveryAddress();

    const { viewMode } = useParams<IPublicProfileRouteParams>();
    const finalOrder = useTypedSelector((state) => state.orderDetails.order);

    const { businessInfo } = useTypedSelector((state) => state.businessInfo);

    const isOrderTypePreOrder = finalOrder.orderType === orderTypeMap.preorder;
    const appViewMode = getOrderMode(viewMode);
    const isCateringViewMode = appViewMode === OrderMode.catering;
    const isPreOrderOnly = businessInfo?.storeOrderingStatus === StoreOrderingState.preOrdersOnly;
    const isTableViewMode = isPreOrderOnly ? false : isNumber(viewMode);
    const { highestDiscount, surcharges, normalizeDiscounts } = usePriceAdjustments();
    const isDeliveryOrder = finalOrder.orderType === "delivery";

    const onSubmitOrderDetails = useCallback(async () => {
        const { userPhoneNumber } = finalOrder;

        const hasErrors = validateForm();
        if (!isTableViewMode && hasErrors) {
            return;
        }

        if (!finalOrder?.businessId) {
            finalOrder.businessId = businessInfo?.businessId ?? '';
        }

        const { priceAdjustments } = surcharges;
        const dscs = normalizeDiscounts(priceAdjustments);
        if (highestDiscount) {
            dscs.push({
                value: highestDiscount.value,
                title: highestDiscount.title,
                type: highestDiscount.type,
                amount: highestDiscount.amount,
                discountType: highestDiscount.discountType,
                maxPurchase: highestDiscount.maxPurchase,
            });
        }

        finalOrder.calculatedDiscounts = dscs;

        const { status, data: newFinalOrder } = await PaymentSheetHelper(finalOrder);
        if (status !== 200) {
            return
        }

        // if (isDeliveryOrder && finalOrder.deliveryAddress) {
        //     const cookieKey = createDeliveryAddressKey(businessInfo);
        //     setCookie(cookieKey, JSON.stringify(finalOrder.deliveryAddress));
        // }

        setCompleteFinalOrder(newFinalOrder);
        setCookie(userPhoneCookieKey, userPhoneNumber);
        handleNext();
    }, [finalOrder, isTableViewMode, highestDiscount])

    const validateForm = () => {
        const { userPhoneNumber, deliveryAddress, customerName, requestedPrepDateTime } = finalOrder;
        let hasErrors = false;
        if (!validateName(customerName)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }

        if (!validateUserPhone(userPhoneNumber)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }

        if (!ValidateDeliveryAddress(deliveryAddress)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }

        if (!validateDateTime(requestedPrepDateTime)) {
            updateCheckoutFormStatus(CheckoutFormStatus.missingFields)
            hasErrors = true;
        }
        return hasErrors;
    };

    const isCustomerContactRequired = useMemo(() =>
        businessInfo?.customerContactInfo === "required"
        , [businessInfo?.customerContactInfo]);

    const validateDateTime = (requestedPrepDateTime?: number) => {
        const isDateTimeOrderTypes = isOrderTypePreOrder || isCateringViewMode;
        if (!isDateTimeOrderTypes) return true;
        return !!requestedPrepDateTime;
    };

    const validateName = (customerName?: string) => {
        if (!businessInfo?.customerNameRequired) return true;
        return !!customerName;
    };

    const validateUserPhone = (number: string) => {
        if (number.length === 0 && isCustomerContactRequired === false) {
            return true;
        }
        return validatePhone(number);
    };

    return <>
        <AppButton onClick={async (e) => {
            setAppLoading()
            await onSubmitOrderDetails()
            setAppLoaded()
        }}>
            View order summary
        </AppButton>
    </>
}


export default CheckoutFooter;